<template>
  <div class="container is-fluid">
    <div class="columns is-desktop vcentered is-centered full-height" v-if="step === 0">
      <div class="column">
        <h1 class="is-size-3-touch is-size-1-desktop title">System losujący pytania</h1>

        <div class="main-select centered">
          <div class="field">
            <label class="label">Kierunek</label>
            <div class="control">
              <div class="select is-medium">
                <select v-model="category">
                  <option v-for="(c, index) in categories" :key="index" :value="c.id">{{c.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="centered">
          <button
            @click="moveToQuestions"
            type="button"
            class="button is-large is-success"
          >Rozpocznij</button>
        </div>
      </div>
    </div>
    <div class="columns is-desktop vcentered is-centered full-height" v-if="step === 1">
      <div class="column">
        <div class="question is-size-3-touch is-size-1-desktop">{{question.title}}</div>

        <div class="centered">
          <button @click="nextQuestion" type="button" class="button is-large is-info">Losuj pytanie</button>
        </div>
        <div class="history">
          <div v-for="(q, i) in receivedQuestions" :key="q">{{i+1}}. {{getQuestionById(q).title}}</div>
        </div>
        <div class="exit">
          <button @click="exit" type="button" class="button is-danger is-medium">Zakończ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const QUESTIONS = {
  "1": "eit",
  "2": "is",
  "3": "ele",
  "4": "ene",
  "5": "teleinf"
};

export default {
  data: () => ({
    step: 0,
    category: null,
    categories: [
      { id: 1, name: "Elektronika i Telekomunikacja studia I stopnia" },
      { id: 2, name: "Informatyka stosowana studia I stopnia" },
      { id: 3, name: "Elektrotechnika studia I stopnia" },
      { id: 4, name: "Energetyka studia I stopnia" },
      { id: 5, name: "Teleinformatyka studia I stopnia" }
    ],
    questions: [],
    receivedQuestions: [],
    question: {}
  }),
  created() {
    window.addEventListener("beforeunload", e => this.beforeunload(e), false);
  },
  methods: {
    getQuestionById(id) {
      return this.questions.find(q => q.id === id) || {};
    },
    moveToQuestions() {
      if (Object.keys(QUESTIONS).indexOf(String(this.category)) === -1) return;

      this.downloadQuestion(QUESTIONS[String(this.category)]);
    },
    downloadQuestion(name) {
      axios.get("/questions/" + name).then(res => this.handleResponse(res));
    },
    handleResponse(res) {
      this.questions = res.data;
      this.step = 1;
    },
    exit() {
      const isOk = confirm("Czy na pewno chcesz zakończyć pytania?");

      if (isOk) {
        this.step = 0;
        this.question = {};
        this.questions = [];
        this.receivedQuestions = [];
      }
    },
    nextQuestion() {
      if (!this.questions.length) return;

      const length = this.questions.length;
      const randomIndex = Math.floor(Math.random() * length);
      const q = this.questions[randomIndex];

      if (this.receivedQuestions.indexOf(q.id) === -1) {
        this.receivedQuestions.push(q.id);

        this.question = q;
      } else {
        this.question = {};

        if (this.receivedQuestions.length !== this.questions.length) {
          this.nextQuestion();
        } else {
          this.question = { title: "Koniec pytań" };
        }
      }
    },
    beforeunload(e) {
      if (this.step !== 1) return;

      e.preventDefault();
      event.returnValue = "";
    }
  }
};
</script>